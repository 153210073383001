import React, { useState, useEffect, useRef } from "react"; 
import jsPDF from "jspdf";
import { Grid, InputLabel, TextField } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import logo from "assets/images/logo_Invet.png"; // Ruta de tu imagen
import { useMutation, gql } from "@apollo/client";

const ADD_FRC_FORM_FILE = gql`
  mutation addFrcFormFile($input: FrcFormFileInput!) {
    addFrcFormFile(input: $input) {
      id
      
    }
  }
`;
const loadImageBase64 = async (imagePath) => {
  const response = await fetch(imagePath);
  const blob = await response.blob();

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob); // Convierte la imagen a base64
  });
};

const ContractPDFLab = () => {
  const pdfRef = useRef(null);
  const [addFrcFormFile] = useMutation(ADD_FRC_FORM_FILE);
    // Estado para el formulario
    const [formData, setFormData] = useState({
      labName: '', 
      protocol: '', 
      city: '', 
      province: '', 
      date: '', 
      bioanalyticProvider: '',
      cuitBioanalyticProvider: '',
      addressBioanalyticProvider: '',
      responsableBioanalyticProvider: '',
      emailBioanalyticProvider: '',
      phoneBioanalyticProvider: '',
      dniBioanalyticProvider: '',
      investigator: '',
      responsableInvestigator: '',
      cuitInvestigator: '',
      addressInvestigator: '',
      dniInvestigator: '',
      labSponsor: '',
      responsableLabSponsor: '',
      emailLabSponsor: '',
      phoneLabSponsor: '',
      studyDescription: '',
      testFormulation: '',
      species: '', 
      price: '', 
      veterinaryCuit: '',
      veterinaryAddress: '',      
      veterinaryOwner: '',        
      veterinaryDni: '',          
      companyCuit: '',            
      companyAddress: '',         
      companyDni: '',             
      mailRepresentative: '',     
      phoneRepresentative: '',    
      mail: '',                   
      phone: '',                  
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };  

    const handleSubmit = (e) => {
      e.preventDefault();
      setFormSubmitted(true); // Se envió el formulario
    };
    // Función para volver a editar el formulario
    const handleBack = () => {
      setFormSubmitted(false); // Vuelve a mostrar el formulario
    };

    useEffect(() => {
      if (!formSubmitted) return;
    
      const generatePDF = async () => {
        const doc = new jsPDF("p", "pt", "a4");
        const pageHeight = doc.internal.pageSize.getHeight();
        const pageWidth = doc.internal.pageSize.getWidth();
        const lineHeight = 14;
        const margin = 40;
        const headerHeight = 120;
        const initialContentOffset = 130; // Desplazar el contenido más abajo (ajusta este valor según sea necesario)
        let y = initialContentOffset;  // Comienza el contenido más abajo que el encabezado
        let pageNumber = 1;
    
        const imgData = await loadImageBase64(logo);
    
        const addHeaderAndFooter = (pageNumber) => {
          const imgWidth = 150;
          const imgHeight = 100;
          const imgX = (pageWidth - imgWidth) / 2;
          const imgY = 10;
    
          // // Coordenadas iniciales para la tabla
          // const tableX = 20;
          // const tableY = imgY + imgHeight + 15; // Justo debajo de la imagen
          // const rowHeight = 15;
          // const colWidths = [110, 110, 110, 110, 110]; // Ancho de cada columna
          // const totalTableWidth = colWidths.reduce((a, b) => a + b);
    
          doc.addImage(imgData, "JPEG", imgX, imgY, imgWidth, imgHeight);
    
          // Agregar título del contrato como una fila que abarca toda la tabla
          // doc.setFontSize(10);
          // doc.setFont("helvetica", "bold");
          // doc.setFillColor(230, 230, 230); // Fondo gris claro para el título
          // doc.rect(tableX, tableY, totalTableWidth, rowHeight, "F"); // Dibujar fondo
          // doc.text(
          //   "CONTRATO DE OBRA CON LABORATORIOS BIOANALITICOS Y PROVEEDORES DE ANALISIS CLINICOS",
          //   tableX + totalTableWidth / 2,
          //   tableY + rowHeight / 2 + 3,
          //   { align: "center" }
          // );
    
          // // Dibujar encabezado de la tabla
          // const headerY = tableY + rowHeight;
          // const headers = ["Código", "Fecha de vigencia", "Próxima Revisión", "Páginas", "Versión"];
          // doc.setFontSize(10);
          // doc.setFont("helvetica", "bold");
          // let currentX = tableX;
          // headers.forEach((header, index) => {
          //   doc.text(header, currentX + 2, headerY + 10); // Agregar texto
          //   currentX += colWidths[index];
          // });
    
          // // Dibujar líneas del encabezado
          // doc.setLineWidth(0.5);
          // doc.line(tableX, headerY, tableX + totalTableWidth, headerY); // Línea superior
          // doc.line(tableX, headerY + rowHeight, tableX + totalTableWidth, headerY + rowHeight); // Línea inferior
    
          // currentX = tableX;
          // colWidths.forEach((width) => {
          //   doc.line(currentX, headerY, currentX, headerY + rowHeight); // Líneas verticales
          //   currentX += width;
          // });
          // doc.line(currentX, headerY, currentX, headerY + rowHeight); // Última línea vertical
    
          // Dibujar datos de la tabla
          // const data = [["AC-002-V01", "1/03/2023", "12/04/2025", "5", "0.1"]];
          // doc.setFont("helvetica", "normal");
          // let dataY = headerY + rowHeight;
          // data.forEach((row) => {
          //   let dataX = tableX;
          //   row.forEach((cell, index) => {
          //     doc.text(cell, dataX + 2, dataY + 10);
          //     dataX += colWidths[index];
          //   });
          //   dataY += rowHeight;
          // });
    
          // Pie de página con número de página
          doc.setFontSize(8);
          doc.text("El presente documento es propiedad del ALIANZA InVet S.R.L. La información contenida es confidencial y se encuentra protegida bajo la LEY Nº 24.766.", margin -20, pageHeight - 30);
          doc.text("Prohibida su reproducción total o parcial.", pageWidth / 3, pageHeight - 20 );
          doc.setFontSize(10);
          doc.text(`Página ${pageNumber}`, pageWidth - margin - 30, pageHeight - 15);
          doc.setFontSize(12);
        };
        addHeaderAndFooter(pageNumber);

      const sections = [
        { text: `CONTRATO DE OBRA`, style: { bold: true, fontSize: 16, align: 'center' } },
        { text: `Entre EL LABORATORIO ${formData.lab} y EL INVESTIGADOR PRINCIPAL DEL ESTUDIO`, style: { fontSize: 12, align: 'center' } },
        { text: `PROTOCOLO ${formData.protocol}`, style: { fontSize: 12, align: 'center' } },
        { text: `Ciudad de ${formData.city} – Provincia de ${formData.province}, a los ${formData.date} días del mes de ${formData.date.split("-")[1]} de ${formData.date.split("-")[0]}.`, style: { fontSize: 12 } },
        { text: `Por una parte, el proveedor bioanalítico ${formData.bioanalyticProvider} (en adelante PROVEEDOR BIOANALITICO), CUIT [${formData.cuitBioanalyticProvider}], con domicilio en ${formData.addressBioanalyticProvider}, representada en éste acto por su Responsable, ${formData.responsableBioanalyticProvider}; DNI ${formData.dniBioanalyticProvider}; `, style: { fontSize: 12 } },
        { text: `Y por otra parte, el Investigador Principal/Co-Investigador Principal (en adelante EL INVESTIGADOR), CUIT ${formData.cuitInvestigator}, con domicilio en ${formData.addressInvestigator}, representado   en   este   acto   por   el   Dr./   Dra. ${formData.responsableInvestigator},   DNI ${formData.dniInvestigator}; `, style: { fontSize: 12 } },
        
        { text: `EXPONEN`, style: { bold: true, fontSize: 14 } },
        { text: `- Que EL PROVEEDOR BIOANALITICO es un agente organizado económicamente y que desarrolla actividades de laboratorio bioanalítico de muestras animales, interesada en colaborar con la ciencia;`, style: { fontSize: 12 } },
        { text: `- Que EL INVESTIGADOR brinda servicios para para el registro de medicamentos veterinarios, demandados por empresas farmacéuticas;`, style: { fontSize: 12 } },
        { text: `- Que tanto EL PROVEEDOR BIOANALITICO como LA EMPRESA están de acuerdo en la participación conjunta de proyectos tanto de ámbito nacional como internacional.`, style: { fontSize: 12 } },
        { text: `Teniendo en cuenta lo anteriormente expuesto, CONVIENEN estrechar sus relaciones, aunar esfuerzos y establecer normas amplias y específicas de actuación que encaucen e incrementen, dentro de un marco preestablecido, los contactos y colaboraciones.`, style: { fontSize: 12 } },
        { text: `A tal fin, se redacta el siguiente acuerdo de colaboración según las siguientes CLAUSULAS:`, style: { fontSize: 12 } },
        
        { text: `PRIMERA: Finalidad.`, style: { bold: true, fontSize: 14 } },
        { text: `EL INVESTIGADOR contrata a EL PROVEEDOR BIOANALITICO con el objeto de ejecutar un proyecto de investigación clínica veterinaria correspondiente al protocolo ${formData.protocol}. La formulación en estudio pertenece al laboratorio patrocinante ${formData.labSponsor}, quien delega la ejecución del estudio en EL INVESTIGADOR. `, style: { fontSize: 12 } },
        { text: `La investigación consiste en un estudio de ${formData.studyDescription}, de una formulación de prueba a base de ${formData.testFormulation}, en la especie ${formData.species}. `, style: { fontSize: 12 } },
        { text: `El proyecto de Investigación Clínica Veterinaria tiene asociado un plan de trabajo que detalla el diseño, las distintas fases a desarrollar y la cronología, como se indica en el protocolo adjunto.`, style: { fontSize: 12 } },
        
        { text: `SEGUNDA: Precio.`, style: { bold: true, fontSize: 14 } },
        { text: `EL INVESTIGADOR pagará a EL PROVEEDOR BIOANALITICO, el valor de ${formData.price} por muestra según el precio al momento del estudio. En el caso de animales que por causa ajena a la voluntad del Veterinario, no pudieran terminar el estudio, se abonará a EL PROVEEDOR BIOANALITICO, el valor los tiempos cumplidos del estudio, con toda la información requerida para ese animal. `, style: { fontSize: 12 } },
        
        { text: `TERCERA: Responsables.`, style: { bold: true, fontSize: 14 } },
        { text: `Por parte de la empresa patrocinante ${formData.labSponsor}, se designa al Dr./Dra ${formData.responsableLabSponsor}, como Investigador/a principal y contacto a cualquier efecto, siendo su correo electrónico ${formData.emailLabSponsor} y su teléfono ${formData.phoneLabSponsor}.`, style: { fontSize: 12 } },
        { text: `Por parte de EL PROVEEDOR BIOANALITICO se designa al Dr./Dra. ${formData.responsableBioanalyticProvider}, como Investigador/a principal; siendo sus datos de contacto de correo electrónica: ${formData.emailBioanalyticProvider}  Teléfono: ${formData.phoneBioanalyticProvider}.`, style: { fontSize: 12 } },
        
        { text: `CUARTA: Duración.`, style: { bold: true, fontSize: 14 } },
        { text: `El presente Acuerdo Marco entrará en vigor en el momento de su firma y su vigencia será por el tiempo que dure la etapa clínica con EL PROVEEDOR BIOANALITICO.`, style: { fontSize: 12 } },
        
        { text: `QUINTA: Terminación y rescisión.`, style: { bold: true, fontSize: 14 } },
        { text: `El presente convenio puede ser rescindido sin expresión de causa por cualquiera de las partes mediante un aviso previo, por medio fehaciente, realizado con una anticipación no menor a los treinta (30) días de la fecha en que se pretende su terminación.`, style: { fontSize: 12 } },
        { text: `La terminación comunicada en tiempo y forma conforme lo dispuesto en la presente cláusula no dará derecho a reclamar indemnización de ninguna naturaleza a ninguna de las partes.`, style: { fontSize: 12 } },
        { text: `En caso de existir trabajos en curso de ejecución las partes se comprometen a mantener en vigencia el convenio específico hasta la finalización de las actividades previstas. En caso de no ser posible por causas verificables, se tratará de llegar a un acuerdo de finalización anticipada de proyectos.`, style: { fontSize: 12 } },
        { text: `El INVESTIGADOR se compromete a pagar por los servicios contratados a EL PROVEEDOR BIOANALITICO, conforme a lo establecido en el apartado “PRECIO” al momento de rescindir el acuerdo, en contraprestación a lo ya realizado.`, style: { fontSize: 12 } },
        
        { text: `SEXTO: Controversias.`, style: { bold: true, fontSize: 14 } },
        { text: `EL PROVEEDOR BIOANALITICO y EL INVESTIGADOR se comprometen a resolver de manera amistosa cualquier desacuerdo que pueda surgir en el desarrollo del presente contrato.`, style: { fontSize: 12 } },
        { text: `En caso de conflicto, se acuerda el sometimiento de ambos a los tribunales federales de la ciudad de locación de EL PROVEEDOR BIOANALITICO y las cámaras de mediación dispuestas a tales efectos.`, style: { fontSize: 12 } },
        
        { text: `SÉPTIMA: Confidencialidad.`, style: { bold: true, fontSize: 14 } },
        { text: `Las partes reconocen que toda la documentación e información relacionada y generada como consecuencia de éste acuerdo de colaboración como así también toda la información que se intercambie por el hecho o en ocasión del presente, la que se obtenga durante la ejecución de proyectos y el resultado del mismo (en adelante LA INFORMACION CONFIDENCIAL) es de exclusiva propiedad de las partes y se considerará confidencial. En tal sentido, se comprometen a:`, style: { fontSize: 12 } },
        { text: `7.1. No usar LA INFORMACION CONFIDENCIAL para otro propósito o fin diferente a la realización del proyecto.`, style: { fontSize: 12 } },
        { text: `7.2. No utilizar LA INFORMACION CONFIDENCIAL para cualquier otro fin sin el previo consentimiento escrito de la otra parte.`, style: { fontSize: 12 } },
        { text: `7.3. Mantener en estricto secreto LA INFORMACION CONFIDENCIAL y restringir el acceso a LA INFORMACION sólo a aquellas personas a su cargo y por ellos designadas para realizar el proyecto, siendo exclusivos responsables de garantizar la confidencialidad de LA INFORMACION CONFIDENCIAL por parte de dichas personas.`, style: { fontSize: 12 } },
        { text: `Quedarán exceptuados de la obligación de confidencialidad respecto de LA INFORMACION CONFIDENCIAL sólo en los siguientes supuestos:`, style: { fontSize: 12 } },
        { text: `a) Se encuentre en el dominio público al tiempo de la celebración del presente acuerdo;`, style: { fontSize: 12 } },
        { text: `b) Sea publicado o se torne de dominio público por causas no imputables a las partes;`, style: { fontSize: 12 } },
        { text: `c) Esté en poder de algunas de las partes al tiempo de la celebración del presente acuerdo;`, style: { fontSize: 12 } },
        { text: `d) Medie requerimiento judicial o de organismo público competente.`, style: { fontSize: 12 } },
        { text: `Las partes reconocen expresamente que las funciones que desarrollarán en relación con las obligaciones consignadas en el presente acuerdo, los pondrán en contacto con los secretos industriales, técnicos, operacionales y comerciales de cada una de ellas, así como de aquellos pertenecientes a sus socios, asociados, accionistas, filiales, subsidiarias, controladoras, clientes, contratistas y cualesquier otra persona relacionada, además del resto de información privilegiada de sus operaciones. Por lo anterior, serán considerados “secretos”, incluyendo, de manera enunciativa más no limitativa, todos aquellos conocimientos industriales, técnicos, comerciales y operacionales, conceptos creativos, propuestas, estrategias de comunicación y publicidad, marcas, diseños, modelos, base de datos, listas de precios, registros, datos, materiales, planes y proyectos de comercialización y ventas, publicidad e información de cualquier tipo relacionada con los negocios y operaciones de las empresas y/o personas relacionadas directa o indirectamente con ésta.`, style: { fontSize: 12 } },
        { text: `La obligación de confidencialidad asumida en éste apartado es a perpetuidad. El incumplimiento de la obligación de confidencialidad, hará responsable a la parte divulgadora de responder integralmente por los daños y perjuicios sufridos por la otra parte como consecuencia de cualquier acto o hecho violatorio de tal obligación.`, style: { fontSize: 12 } },
        { text: `En prueba de conformidad, firman por duplicado el presente acuerdo.`, style: { fontSize: 12 } },
        { text: ``, style: { fontSize: 12 } },
        { text: ``, style: { fontSize: 12 } },
        { text: ``, style: { fontSize: 12 } },
      
        { text: `Firma: ___________________           Firma:_____________________`, style: { fontSize: 12, align: 'center' } },
      ];
      

      sections.forEach((section) => {
        doc.setFontSize(section.style.fontSize || 12);
        if (section.style.bold) {
          doc.setFont("helvetica", "bold");
        } else {
          doc.setFont("helvetica", "normal");
        }
  
        const textLines = doc.splitTextToSize(section.text, pageWidth - 2 * margin);
        textLines.forEach((line) => {
          if (y + lineHeight > pageHeight - margin) {
            doc.addPage();
            pageNumber++;
            addHeaderAndFooter(pageNumber); // Actualizar el encabezado en la nueva página
            y = initialContentOffset; 
          }
          doc.text(line, margin, y);
          y += lineHeight;
        });
        y += lineHeight; // Espacio extra entre secciones
      });
  
      // Generar la URL del PDF en base64 y mostrarlo
      const pdfData = doc.output("datauristring");
      // Guardar el PDF
      const input = {
        file: pdfData,  // Base64 del PDF
        fileName: `contract_${formData.protocol}.pdf`,  // Nombre del archivo
        fileType: "application/pdf",  // Tipo de archivo
      };
  
      try {
        const response = await addFrcFormFile({ variables: { input } });
        console.log("PDF guardado con éxito:", response.data);
      } catch (error) {
        console.error("Error al guardar el PDF:", error.message || error);
      }
  
      if (pdfRef.current) {
        pdfRef.current.src = pdfData;  // Si quieres mostrar el PDF generado
      }
    };
  
    generatePDF();
  }, [formSubmitted, formData]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox sx={{ maxWidth: "100%", padding: "2rem" }}>
        <SoftTypography variant="h6" fontWeight="bold" mb={3}>
          Complete los Campos del Contrato con Laboratorio
        </SoftTypography>
        <Grid container spacing={2} alignItems={"flex-end"}></Grid>
        {/* Formulario de campos */}
        {!formSubmitted ? (
          <form
            onSubmit={handleSubmit}
            style={{
              maxWidth: "100%",
              margin: "0 auto",
              padding: "1rem",
              backgroundColor: "#f8f9fa",
              borderRadius: "8px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid container spacing={2}>
              {/* Nombre del Laboratorio */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Nombre del Laboratorio
                </InputLabel>
                <TextField
                  fullWidth
                  name="lab"
                  value={formData.lab}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* CUIT del Laboratorio */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  CUIT del Laboratorio
                </InputLabel>
                <TextField
                  fullWidth
                  name="cuitLab"
                  value={formData.cuitLab}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Domicilio del Laboratorio */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Domicilio del Laboratorio
                </InputLabel>
                <TextField
                  fullWidth
                  name="addressLab"
                  value={formData.addressLab}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Responsable del Laboratorio */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Responsable del Laboratorio
                </InputLabel>
                <TextField
                  fullWidth
                  name="responsableLab"
                  value={formData.responsableLab}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* DNI Responsable del Laboratorio */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  DNI Responsable del Laboratorio
                </InputLabel>
                <TextField
                  fullWidth
                  name="dniLab"
                  value={formData.dniLab}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Nombre del Investigador */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Nombre del Investigador
                </InputLabel>
                <TextField
                  fullWidth
                  name="investigator"
                  value={formData.investigator}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* CUIT del Investigador */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  CUIT del Investigador
                </InputLabel>
                <TextField
                  fullWidth
                  name="cuitInvestigator"
                  value={formData.cuitInvestigator}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Domicilio del Investigador */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Domicilio del Investigador
                </InputLabel>
                <TextField
                  fullWidth
                  name="addressInvestigator"
                  value={formData.addressInvestigator}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Responsable del Investigador */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Responsable del Investigador
                </InputLabel>
                <TextField
                  fullWidth
                  name="responsableInvestigator"
                  value={formData.responsableInvestigator}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* DNI Responsable del Investigador */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  DNI Responsable del Investigador
                </InputLabel>
                <TextField
                  fullWidth
                  name="dniInvestigator"
                  value={formData.dniInvestigator}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Fecha de Inicio */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Fecha de Inicio del Estudio
                </InputLabel>
                <TextField
                  fullWidth
                  name="date"
                  type="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Ciudad */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Ciudad
                </InputLabel>
                <TextField
                  fullWidth
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Provincia */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Provincia
                </InputLabel>
                <TextField
                  fullWidth
                  name="province"
                  value={formData.province}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Protocolo */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Protocolo
                </InputLabel>
                <TextField
                  fullWidth
                  name="protocol"
                  value={formData.protocol}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Descripción del Estudio */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Descripción del Estudio
                </InputLabel>
                <TextField
                  fullWidth
                  name="studyDescription"
                  value={formData.studyDescription}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Formulación en Estudio */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Formulación en Estudio
                </InputLabel>
                <TextField
                  fullWidth
                  name="testFormulation"
                  value={formData.testFormulation}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Especie */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Especie
                </InputLabel>
                <TextField
                  fullWidth
                  name="species"
                  value={formData.species}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Proveedor Bioanalítico */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Proveedor Bioanalítico
                </InputLabel>
                <TextField
                  fullWidth
                  name="bioanalyticProvider"
                  value={formData.bioanalyticProvider}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* CUIT del Proveedor Bioanalítico */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  CUIT del Proveedor Bioanalítico
                </InputLabel>
                <TextField
                  fullWidth
                  name="cuitBioanalyticProvider"
                  value={formData.cuitBioanalyticProvider}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Domicilio del Proveedor Bioanalítico */}
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Domicilio del Proveedor Bioanalítico
                </InputLabel>
                <TextField
                  fullWidth
                  name="addressBioanalyticProvider"
                  value={formData.addressBioanalyticProvider}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
  
              {/* Botón de envío */}
              <Grid item xs={12}>
                <SoftButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ marginTop: "1.5rem", borderRadius: "8px" }}
                >
                  Generar Contrato
                </SoftButton>
              </Grid>
            </Grid>
          </form>
        ) : (
          <>
            <embed ref={pdfRef} type="application/pdf" width="100%" height="1000px" />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <SoftButton
                  onClick={handleBack}
                  variant="contained"
                  color="dark"
                  fullWidth
                  sx={{ marginTop: "1rem", borderRadius: "8px" }}
                >
                  Volver a Editar
                </SoftButton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftButton
                  variant="contained"
                  color="success"
                  fullWidth
                  sx={{ marginTop: "1rem", borderRadius: "8px" }}
                >
                  Guardar Contrato
                </SoftButton>
              </Grid>
            </Grid>
          </>
        )}
      </SoftBox>
    </DashboardLayout>
  );
};

export default ContractPDFLab; 