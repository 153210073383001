
import React, { useState, useEffect, useRef } from "react";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { Grid, InputLabel, TextField } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import logo from "assets/images/logo_Invet.png"; // Ruta de tu imagen
import img1 from "assets/images/img1.jpg";
import img2 from "assets/images/img2.jpg";
import img3 from "assets/images/img3.png";
import { useMutation, gql } from "@apollo/client";



const ADD_FRC_FORM_FILE = gql`
  mutation addFrcFormFile($input: FrcFormFileInput!) {
    addFrcFormFile(input: $input) {
      id
      
    }
  }
`;
// Función para cargar la imagen en base64
const loadImageBase64 = async (imagePath) => {
  const response = await fetch(imagePath);
  const blob = await response.blob();

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob); // Convierte la imagen a base64
  });
};

const ContractConsentimiento = () => {
  const pdfRef = useRef(null);
  const [addFrcFormFile] = useMutation(ADD_FRC_FORM_FILE);
  // Estado para el formulario
  const [formData, setFormData] = useState({
    city: '',
    province: '',
    date: '',
    DrDra: '',
    veterinary: '',
    phone: '',
    MP: '',
    name: '',
    domicilio: '',
    telefono: '',
    animalName: '',
    raza: '',
    sexo: '',
    edad: '',

  });


  const [formSubmitted, setFormSubmitted] = useState(false);

  // Función para manejar los cambios en el formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true); // Se envió el formulario
  };
  // Función para volver a editar el formulario
  const handleBack = () => {
    setFormSubmitted(false); // Vuelve a mostrar el formulario
  };

  useEffect(() => {
    if (!formSubmitted) return;

    const generatePDF = async () => {
      const doc = new jsPDF("p", "pt", "a4");
      const pageHeight = doc.internal.pageSize.getHeight();
      const pageWidth = doc.internal.pageSize.getWidth();
      const lineHeight = 14;
      const margin = 40;
      const headerHeight = 120;
      let y = headerHeight;

      const imgData = await loadImageBase64(logo);
      const imgData1 = await loadImageBase64(img1);
      const imgData2 = await loadImageBase64(img2);
      const imgData3 = await loadImageBase64(img3);

      const addHeaderAndFooter = (pageNumber) => {
        const imgWidth = 150;
        const imgHeight = 100;
        const imgX = (pageWidth - imgWidth) / 2;
        const imgY = 10;

        doc.setFontSize(10);
        doc.addImage(imgData, "JPEG", imgX, imgY, imgWidth, imgHeight);

        // Pie de página con número de página
        doc.setFontSize(10);
        doc.text(`Página ${pageNumber}`, pageWidth - margin - 25, pageHeight - 20);
        doc.setFontSize(10);
      };

      let pageNumber = 1;
      addHeaderAndFooter(pageNumber);

      const sections = [
        { text: `CONSENTIMIENTO INFORMADO PARA EL TENEDOR RESPONSABLE DEL ANIMAL DEL ESTUDIO`, style: { bold: true, fontSize: 12 } },
        { text: `Investigador Principal: Dra. Natalia M. Cardillo – Dr. Diego Díaz David`, style: { fontSize: 10 } },
        { text: `Patrocinante: GREFMAYER SRL otorga mandato expreso al Dr./Dra ${formData.DrDra} con facultades específicas para que lo represente en la República Argentina ante SENASA y otras Instituciones para las actividades referidas exclusivamente al Estudio contratado.`, style: { fontSize: 10 } },
        { text: `Título del estudio: Estudio a campo ambulatorio de eficacia y seguridad de una formulación tópica combinada a base de Imidacloprid (6.0 % p/v), Ivermectina (0,25 % p/v), Praziquantel (6,0 % p/v) y Piriproxifeno (0.5 % p/v) para el control de pulgas y helmintos gastrointestinales en felinos con infestación natural.`, style: { fontSize: 10 } },

        { text: `Estimado señor / señora:`, style: { fontSize: 10 } },
        { text: `Usted ha sido convocado a participar en un proyecto en el que se determinará la seguridad y la eficacia en el control de pulgas y helmintos gastrointestinales en felinos con infestación natural, de una formulación a base de los principios activos Imidacloprid, Piriproxifen, Ivermectina y Praziquantel, de aplicación tópica en pipetas, del Laboratorio GREFMAYER SRL. Dicha formulación, se comparará con el producto de referencia Feline Full-spot® del Laboratorio Labyes S.A., también de aplicación tópica en pipetas. Su gato recibirá una de las dos formulaciones, la cual será indicada por el Médico Veterinario en el momento del tratamiento.`, style: { fontSize: 10 } },
        { text: `Previo a que usted acepte la participación de su/s animal/es en este estudio, por favor, lea detenidamente este formulario de consentimiento y formule todas las preguntas que desee.`, style: { fontSize: 10 } },
        { text: `ESTE FORMULARIO DE CONSENTIMIENTO CONTIENE INFORMACIÓN IMPORTANTE PARA AYUDARLO A DECIDIR LA PARTICIPACION O NO DE SU ANIMAL EN EL ESTUDIO. Si tiene preguntas que no se encuentran explícitas o respondidas de manera adecuada, alguna persona del equipo de investigación estará disponible para proporcionarle más información.`, style: { fontSize: 10 } },

        { text: `Descripción del Estudio`, style: { bold: true, fontSize: 12 } },
        { text: `¿Qué es un estudio de Eficacia y porqué se realiza este estudio?`, style: { bold: true, fontSize: 12 } },
        { text: `La eficacia de un medicamento veterinario se define por la capacidad de la sustancia activa de ese fármaco, de provocar el efecto esperado para el que fue diseñado. Para este medicamento, se espera que la combinación de los principios activos Imidacloprid, Piriproxifen, Ivermectina y Praziquantel, controlen eficientemente las pulgas y parásitos gastrointestinales en los felinos, y su uso sea seguro para la especie.`, style: { fontSize: 10 } },
        { text: `Luego de la administración tópica de la formulación, los principios activos se distribuyen por la piel, a través de la sustancia oleosa secretada por las glándulas sebáceas del animal, y se absorben para estar disponibles en el/los sitio(s) de acción donde se encuentran las pulgas y los gusanos gastrointestinales.`, style: { fontSize: 10 } },
        { text: `En las formulaciones tópicas, una serie de factores como los excipientes, las distintas combinaciones de principios activos y sus concentraciones, y la resistencia de los parásitos a algunas drogas, pueden provocar diferencias en los niveles de efectividad. Los fallos terapéuticos asociados a estas diferencias promueven la necesidad de comparar cada nueva formulación con alguna que ya exista en el mercado y sobre la que su seguridad y eficacia ha sido estudiada previamente (este será el medicamento de referencia). Estudiando y comparando la respuesta de ambas en el control de pulgas y gusanos gastrointestinales, se podrá inferir si tendrán efectos y resultados terapéuticos esencialmente similares. A través de este estudio, se podrá asegurar científicamente que el producto nuevo se comportará, al menos, de la misma manera en efectividad y seguridad que el producto registrado previamente, y por tal motivo podrá ser presentado ante la autoridad sanitaria SENASA para su evaluación y aprobación de comercialización.`, style: { fontSize: 10 } },

        { text: `¿Para qué se utiliza el medicamento en los animales?`, style: { bold: true, fontSize: 12 } },
        { text: `Las pulgas que encontramos indistintamente en perros y gatos, tienen predilección por alimentarse de la sangre de ellos primero, pero pueden también picar a otras especies animales convivientes o al hombre. Las pulgas, además, también son transmisoras de otras enfermedades parasitarias, virales y bacterianas de importancia en los perros y los gatos, de allí la relevancia de su control. Los gusanos gastrointestinales viven dentro del estómago e intestino y causan daño por ocupar espacio, quitándole los nutrientes que consume el animal o alimentándose de su sangre, y por la acción tóxica de los productos de deshecho que eliminan. Varios de ellos también pueden afectar al hombre, especialmente a los niños.`, style: { fontSize: 10 } },
        { text: `Las pulgas adultas se alimentan de la sangre de sus hospedadores, pero la mayor parte de su ciclo de vida ocurre en el ambiente. Las hembras buscan sitios preferentemente cálidos, con poca luz y húmedos para poner sus huevos (15 a 20 por día) y habitualmente donde permanece o descansa el animal, como alfombras, tapizados, camas de los animales, lugares con arena que les proporcionan protección. De los huevos emerge una larva que se alimenta de la materia fecal de la pulga adulta (sangre) y de materia orgánica del ambiente. La larva pasa por tres estadios larvarios, luego forma una pupa o capullo de pocos milimetros, en cuyo interior se transforma de larva en pulga adulta para reiniciar el ciclo.`, style: { fontSize: 10 } },
        { text: `Estos ciclos, en temporadas cálidas o en hogares calefaccionados y con presencia de hospedadores, se producen cada 2 a 3 semanas, pero las pulgas pueden permanecer meses dentro del capullo hasta encontrar condiciones favorables para emerger y reiniciar el ciclo. Es por ello, que la mayoría de las pulgas pasa el invierno en estado de larva o pupa, incrementándose la infestación hacia la primavera.`, style: { fontSize: 10 } },

        { text: `Los gusanos gastrointestinales afectan notablemente a los gatitos en edades tempranas, debido a su baja protección inmunológica y a que algunos se los transmiten las mamás durante la lactancia. Los gusanos que se desarrollan a adultos en el estómago e intestino ponen millones de huevos que son eliminados al ambiente con la materia fecal. En esos huevos se desarrolla una larva en cuestión de días. Los animales, e incluso el hombre, también pueden ingerir estos huevos o larvas presentes en el pasto, juguetes contaminados, o en contacto con sus mamás en ambientes sucios. Las larvas de algunos parásitos también pueden penetrar la piel sana de los animales y el hombre.`, style: { fontSize: 10 } },

        { text: `Las formulaciones en estudio contienen combinaciones de drogas con potente efecto sobre los estadios adultos y juveniles de las pulgas y los gusanos gastrointestinales. Su actividad la ejercen sobre la piel y por penetración a través de la misma, para llegar por la sangre donde se alojan los parásitos, que las ingieren cuando se alimentan. Actúan selectivamente sobre el sistema nervioso, causándole la muerte. Poseen un poder residual de aproximadamente 1 mes post-administración, por lo que su efecto se mantiene durante este tiempo y se incrementa con el uso mensual continuo, debido a que en el caso de las pulgas, elimina paulatinamente los estadios ambientales.`, style: { fontSize: 10 } },

        { text: `¿Cómo se realizará el estudio?`, style: { bold: true, fontSize: 12 } },
        { text: `Se realizará en gatos sanos de ambos sexos, a partir de las 10 semanas de edad. En el día de tratamiento, el gato deberá concurrir a la veterinaria acompañado por usted o por quien usted autorice por escrito. El médico veterinario le realizará a su gato, un examen clínico general para determinar el estado de salud conforme a parámetros de peso, estado general, frecuencia cardíaca, frecuencia respiratoria, hidratación, mucosas, y temperatura.`, style: { fontSize: 10 } },
        { text: `Se registrarán las medicaciones recibidas dentro del plan sanitario (vacunas y antiparasitarios), no debiendo haber recibido previamente tratamientos contra pulgas o gusanos gastrointestinales por un período mínimo de 60 días previos al estudio. El veterinario será quien determine si el animal se encuentra apto para recibir la medicación de prueba o de referencia y así le será informado, para que pueda firmar la aceptación del presente consentimiento. De encontrarse apto, ante la sospecha de infección por gusanos gastrointestinales, el veterinario le proporcionará un frasco recolector, para que coloque una porción (una cucharadita de té) de materia fecal, recién emitida, de las deposiciones de 1 día.`, style: { fontSize: 10 } },

        { text: `Evaluación de la eficacia de las formulaciones en estudio`, style: { bold: true, fontSize: 12 } },
        { text: `Si su animal presenta infestación por pulgas, recibirá el tratamiento en ese mismo momento, posteriormente al examen clínico. Su animal será pesado durante el examen clínico, y recibirán la pipeta correspondiente a la dosis para ese peso de la formulación de Prueba (pipeta de uso tópico del laboratorio GrefmayerS.A.) o de Referencia (pipeta de uso tópico Feline Full-spot® del Laboratorio Labyes S.A.), según haya sido asignada la formulación para su gato. Si no presentara infección por pulgas, se esperará al resultado del análisis de materia fecal para saber si es necesario el tratamiento.`, style: { fontSize: 10 } },
        { text: `Los tratamientos serán realizados preferentemente, por el médico veterinario a cargo de la atención de su animal, por concurrencia del animal a la veterinaria o visita del veterinario al hogar, según sea la metodología convenida por ambos.`, style: { fontSize: 10 } },
        { text: `Para determinar la eficacia de las formulaciones, se realizará el recuento gusanos gastroinestinales, y de pulgas, durante la primera visita (previo a la administración de la formulación), y en visitas posteriores, siguiendo el esquema 1 al final del documento.`, style: { fontSize: 10 } },

        { text: `El recuento de pulgas pos-tratamiento se realizará mediante recolección de los parásitos adultos por peinado continuo durante 5- 10 minutos, según la densidad del pelaje. En el caso en que se continúen encontrando pulgas luego de ese tiempo de peinado, se continuará con la maniobra hasta la ausencia de su recuperación durante otros 5 - 10 minutos de peinado continuo. Los recuentos de pulgas se llevarán a cabo por personal que podrá ser un veterinario o auxiliar, debidamente entrenado.`, style: { fontSize: 10 } },

        { text: `Si su gato convive con hasta 2 dos animales más (perros o gatos), se designará al gato que se presente a la consulta como el animal primario para el estudio de eficacia y será sobre el que se realicen los recuentos de pulgas y de gusanos gastrointestinales. Todos los felinos del mismo hogar, serán tratados con el mismo tratamiento y el veterinario le suministrará medicación en cantidad suficiente para que usted pueda tratar a los animales convivientes (siempre que se encuentren sanos). Los felinos convivientes tratados serán designados como animales secundarios para el estudio de eficacia pero serán considerados para la evaluación de la seguridad. Al firmar el presente consentimiento deberá abstenerse del uso de cualquier producto insecticida ambiental o en los animales, (a excepción de la formulación otorgada por el veterinario), así como cualquier otro medicamento sin autorización previa de éste, quien evaluará si posee algún efecto que pudiera interactuar con el producto administrado, así como la conveniencia o no de su aplicación.`, style: { fontSize: 10 } },

        { text: `Evaluación de la seguridad de las formulaciones en estudio`, style: { bold: true, fontSize: 12 } },
        { text: `Previo al momento de la administración del tratamiento, y para ambas formulaciones, se realizará la evaluación clínica de los animales. En el día del tratamiento de cada animal, se le brindarán indicaciones para que observe el comportamiento todos los animales que han sido tratados, durante el período de estudio. Paralelamente, los investigadores realizarán comunicaciones telefónicas para indagar sobre la presentación de algún signo o comportamiento inusual que pudiera relacionarse con la administración de la medicación. Se realizará una evaluación final a los 28 días, aproximadamente, para constatar el estado clínico y el alta definitiva del estudio. El equipo, los suministros y el personal médico veterinario están adecuadamente capacitados y estarán disponibles para tratar cualquier evento adverso que pudiera ocurrir`, style: { fontSize: 10 } },

        { text: `¿Las maniobras de peinado o extracción de muestras de materia fecal, suponen algún riesgo para el animal?`, style: { bold: true, fontSize: 12 } },
        { text: `La maniobra de peinado no supone ningún riesgo para el animal, y en un animal dócil hasta puede resultar placentera. La extracción de muestras de materia fecal se realiza luego de la defecación del animal, no realizando ninguna maniobra sobre él.`, style: { fontSize: 10 } },

        { text: `¿Existe algún riesgo para el animal derivado de la administración de los productos en estudio?`, style: { bold: true, fontSize: 12 } },
        { text: `Con los principios activos contenidos en las formulaciones de Prueba y de Referencia, por administración tópica, poseen alto margen de seguridad y baja toxicidad para los felinos, puesto que los mismos prácticamente no se absorben a través de la piel y tienen muy alta selectividad por el sistema nervioso de los invertebrados (pulgas). El reporte global de eventos adversos en felinos ha sido infrecuente en la presentación spot-on, y en su mayoría relacionados con reacciones de hipersensibilidad local en el sitio de aplicación o ingestión accidental, consistente enalopecía, eritema, costras y prurito. Estas reacciones dérmicas ocurrieron inmediatamente luego de la aplicación. Existen reportes de signos gastrointestinales escasos, con vómitos y diarrea, probablemente relacionados a un efecto irritativo asociado a la ingestión oral del producto, por tal motivo, la aplicación debe ser exclusivamente en la base del cuello para evitar el lamido.`, style: { fontSize: 10 } },

        { text: `¿Cómo se procederá en caso de presentarse algún efecto adverso en algún animal?`, style: { bold: true, fontSize: 12 } },
        { text: `Si bien no se anticipa que ocurran eventos adversos serios, de presentarse algún evento de emergencia en cualquier momento del estudio, se le indicará la notificación telefónica inmediata al veterinario, quien evaluará la urgencia y realizará la intervención médica correspondiente y el seguimiento hasta la remisión de los signos clínicos.`, style: { fontSize: 10 } },

        { text: `Beneficios asociados con el estudio`, style: { bold: true, fontSize: 12 } },
        { text: `Usted y la sociedad pueden beneficiarse con la investigación clínica por contar con una alternativa en el mercado de un nuevo producto con efectividad y seguridad demostrada, y no inferior a la del producto de referencia comercializado en el mercado. Los animales involucrados en el estudio contarán con un chequeo clínico, y así como otros gatos convivientes en el mismo hogar, con el beneficio derivado del tratamiento antiparasitario, que mantendrá una protección frente a pulgas y helmintos gastrointestinales de al menos, 1 mes post-tratamiento.`, style: { fontSize: 10 } },

        { text: `Confidencialidad`, style: { bold: true, fontSize: 12 } },
        { text: `Al firmar este formulario de consentimiento informado usted autoriza (da su permiso) al personal del ensayo para obtener y utilizar información que surja del estudio con su gato. Todos los registros que identifiquen el lugar, en el caso de necesitar acudir a su domicilio, serán mantenidos con carácter confidencial y no serán dados a conocer públicamente. Es posible que la información y los resultados de este estudio sean presentados en congresos y publicados en revistas. Las presentaciones y publicaciones no incluirán información que pueda conducir a identificarlo a usted, a su animal, como tampoco a los lugares donde se desarrollará el estudio, eventualmente.`, style: { fontSize: 10 } },

        { text: `Participación y terminación`, style: { bold: true, fontSize: 12 } },
        { text: `La decisión de hacer participar a su gato en este estudio es voluntaria. Usted puede rehusarse a participar o retirar a su animal del mismo, en cualquier momento. En caso de que necesite información adicional con respecto a este estudio puede contactarse con:Dr.${formData.veterinary}., M.P. ${formData.MP}, Teléfono: ${formData.phone}. `, style: { fontSize: 10 } },
        { text: `-------------------------------`, style: { fontSize: 10 } },
        { text: `Firma Investigador Responsable.`, style: { fontSize: 10 } },


        { text: `CONSENTIMIENTO INFORMADO PARA ESTUDIO CLÍNICO EN ANIMALES`, style: { bold: true, fontSize: 12 } },
        { text: `Título del estudio: Estudio a campo ambulatorio de eficacia y seguridad de una formulación tópica combinada a base de Imidacloprid (6.0 % p/v), Piriproxifen (0.5 % p/v), Ivermectina (0,25 % p/v) y Praziquantel (6.0 % p/v), para el control de pulgas y helmintos gastrointestinales de felinos con infestación natural.`, style: { fontSize: 10 } },

        { text: `Investigador Secundario (Veterinario)`, style: { bold: true, fontSize: 12 } },
        { text: `El que suscribe, Sr./Sra:${formData.SrSra} con domicilio en ${formData.domicilio}, de la ciudad de ${formData.city} , Provincia de ${formData.province} , teléfono ${formData.telefono} , tenedor responsable o propietario del felino de nombre ${formData.animalName}, sexo ${formData.sexo} , edad ${formData.edad}, raza ${formData.raza} `, style: { fontSize: 10 } },

        { text: `Tomo conocimiento respecto al estudio, cuyo objetivo es determinar la eficacia de una formulación tópica de prueba de una combinación de Imidacloprid, Piriproxifen, Ivermectina y Praziquantel, para el control de pulgas y gusanos gastrointestinales del gato, en comparación con una formulación de referencia registrada y comercializada en el país, con el mismo espectro de acción. El objetivo es determinar si la formulación de prueba es, al menos, tan eficaz en el control de pulgas y gusanos gastrointestinales como la formulación de referencia del mercado. Para ello, se incluirán en el ensayo hogares en los que viva 1 felino y hogares en los que convivan hasta un máximo de 3 felinos. En hogares con más de un felino, se designará al animal que se presente a la consulta como el animal primario para el estudio de eficacia y será sobre el que se realicen los recuentos de pulgas y/o gusanos gastrointestinales.`, style: { fontSize: 8 } },
        { text: `El felino bajo mi tenencia y cuidados, designado como animal primario para el estudio, será evaluado clínicamente para determinar si cumple con las condiciones para participar del mismo, en cuyo caso, el médico veterinario le dará un frasco para la recolección de la materia fecal para determinar la presenta de gusanos gastrointestinales. Si presenta pulgas, administrará una de las dos formulaciones mencionadas, de lo contrario, se esperara al resultado del análisis para determinar si es necesario el tratamiento. Al día siguiente, y a los días 15 y 30 postratamiento, el veterinario o un auxiliar designado por él, lo revisará nuevamente y lo peinará para detectar y contabilizar la presencia de pulgas, y le solicitará una nueva muestra de materia fecal. En la última fecha, luego del examen clínico se le otorgará el alta del estudio. De existir alguna complicación, deberé informar oportunamente y se tomarán las medidas paliativas pertinentes para resolver la situación.`, style: { fontSize: 8 } },
        { text: ` Todos los felinos convivientes bajo mi tenencia, serán tratados con el mismo tratamiento (según la asignación aleatoria del animal primario al grupo de tratamiento de Prueba o al de Referencia), y serán designados como animales secundarios para el estudio de eficacia. Para la evaluación de la seguridad, serán considerados todos los animales tratados.Manifiesto que he recibido y entendido la información sobre el procedimiento terapéutico, teniendo conocimiento de los riesgos y/o posibles complicaciones que pudieran surgir. He leído y comprendido la información precedente y se me ha dado una respuesta satisfactoria a todas las preguntas por mí formuladas.Acepto voluntariamente la participación de mi/s gato/s en esta investigación clínica, declarando que el/los mismo/s no presenta/n problemas de salud preexistentes que sean de mi conocimiento. Por tal motivo, autorizo a los veterinarios involucrados en el estudio o a los auxiliares designados, a realizar el procedimiento terapéutico en el/los gato/s de mi propiedad, comprendiendo el significado del mismo y sus riesgos, teniendo conocimiento de que este consentimiento puede ser revocado en cualquier momento.`, style: { fontSize: 8 } },
        { text: `"El abajo firmante da fe y asumen en carácter de declaración jurada, que los animales participantes en el citado ensayo se encuentran en buen estado de salud, bajo cuidados de tenencia ética y responsable y en cumplimiento de las normas sanitarias vigente: Vacunación Antirrábica anual en vigencia- Ley 22.953.” SENASA, GDPV Nº 1. En caso que corresponda, se extiende el presente consentimiento informado al tratamiento de los siguientes animales secundarios, bajo mi tenencia: `, style: { fontSize: 8 } },

        { text: `En este acto, se me entrega copia del presente Formulario de Consentimiento Informado`, style: { fontSize: 10, bold: true } },
        { text: `En: ${formData.city}, ${formData.province}, Fecha: ${formData.date}`, style: { fontSize: 10, bold: true } },

        { text: `--------------------------------------------`, style: { fontSize: 10, bold: true } },
        { text: `Firma Tutor Responsable`, style: { fontSize: 10, bold: true } },


      ];
      sections.forEach((section) => {
        // Verificar si 'style' está definido
        const style = section.style || {};
        doc.setFontSize(style.fontSize || 10);

        // Establecer el estilo de la fuente
        if (style.bold) {
          doc.setFont("helvetica", "bold");
        } else {
          doc.setFont("helvetica", "normal");
        }

        // Dividir el texto en líneas
        const textLines = doc.splitTextToSize(section.text, pageWidth - 2 * margin);

        // Verificar si hay suficiente espacio para todas las líneas
        if (y + (textLines.length * lineHeight) > pageHeight - margin) {
          doc.addPage();
          pageNumber++;
          addHeaderAndFooter(pageNumber);
          y = headerHeight;
        }

        // Agregar cada línea de texto
        textLines.forEach((line) => {
          if (y + lineHeight > pageHeight - margin) {
            doc.addPage();
            pageNumber++;
            addHeaderAndFooter(pageNumber);
            y = headerHeight;
          }
          doc.text(line, margin, y);
          y += lineHeight;
        });

        // Espaciado adicional entre secciones
        y += lineHeight;
      });

      // Insertar imagen 1
      const imgWidth1 = 150; // Ancho de la imagen 1
      const imgHeight1 = 150; // Alto de la imagen 1
      const imgMargin = 20; // Margen horizontal entre imágenes
      const totalImgWidth = imgWidth1 * 3 + imgMargin * 2; // Ancho total de las 3 imágenes con margen
      const imgX1 = (pageWidth - totalImgWidth) / 2; // Coordenada x de la primera imagen (centrada)
      const imgY1 = y + 10; // Coordenada y de las imágenes

      // Insertar imagen 1
      doc.addImage(imgData1, "PNG", imgX1, imgY1, imgWidth1, imgHeight1);

      // Insertar imagen 2
      const imgX2 = imgX1 + imgWidth1 + imgMargin; // Coordenada x de la segunda imagen
      doc.addImage(imgData2, "PNG", imgX2, imgY1, imgWidth1, imgHeight1);

      // Insertar imagen 3
      const imgX3 = imgX2 + imgWidth1 + imgMargin; // Coordenada x de la tercera imagen
      doc.addImage(imgData3, "PNG", imgX3, imgY1, imgWidth1, imgHeight1);

      // Actualizar la posición y para el siguiente contenido
      y = imgY1 + imgHeight1 + 10;

      // Generar la URL del PDF en base64 y mostrarlo
      const pdfData = doc.output("datauristring");
      // Guardar el PDF
      const input = {
        file: pdfData,  // Base64 del PDF
        fileName: `contract_${formData.protocol}.pdf`,  // Nombre del archivo
        fileType: "application/pdf",  // Tipo de archivo
      };

      try {
        const response = await addFrcFormFile({ variables: { input } });
        console.log("PDF guardado con éxito:", response.data);
      } catch (error) {
        console.error("Error al guardar el PDF:", error.message || error);
      }

      if (pdfRef.current) {
        pdfRef.current.src = pdfData;  // Si quieres mostrar el PDF generado
      }
    };

    generatePDF();
  }, [formSubmitted, formData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox sx={{ maxWidth: "100%", padding: "2rem" }}>
        <SoftTypography variant="h6" fontWeight="bold" mb={3}>
          Complete los Campos del Contrato Consentimiento
        </SoftTypography>
        <Grid container spacing={2} alignItems={"flex-end"}></Grid>
        {/* Formulario de campos */}
        {!formSubmitted ? (
          <form
            onSubmit={handleSubmit}
            style={{
              maxWidth: "100%",
              margin: "0 auto",
              padding: "1rem",
              backgroundColor: "#f8f9fa",
              borderRadius: "8px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid container spacing={2}>
              {/* Nombre del Laboratorio */}
              <Grid item xs={10} sm={6} md={4}>
                <InputLabel sx={{ fontSize: "0.875rem", color: "#333" }}>
                  Nombre del Laboratorio
                </InputLabel>
                <TextField
                  fullWidth
                  name="lab"
                  value={formData.lab}
                  onChange={handleChange}
                  required
                  sx={{
                    fontSize: "0.875rem",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": { padding: "10px" },
                  }}
                />
              </Grid>
              {/* Fecha */}
              <Grid item xs={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>Fecha (YYYY-MM-DD)</InputLabel>
                <TextField
                  fullWidth
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>
              {/* Ciudad */}
              <Grid item xs={4} sm={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>Ciudad</InputLabel>
                <TextField
                  fullWidth
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>

              {/* Provincia */}
              <Grid item xs={4} sm={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>Provincia</InputLabel>
                <TextField
                  fullWidth
                  name="province"
                  value={formData.province}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>
              {/* Dr/Dra */}
              <Grid item xs={4} sm={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>Dr/Dra</InputLabel>
                <TextField
                  fullWidth
                  name="DrDra"
                  value={formData.DrDra}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>
              {/* Veterinario */}
              <Grid item xs={4} sm={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>Veterinario</InputLabel>
                <TextField
                  fullWidth
                  name="veterinary"
                  value={formData.veterinary}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>
              {/* MP */}
              <Grid item xs={4} sm={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>MP</InputLabel>
                <TextField
                  fullWidth
                  name="MP"
                  value={formData.MP}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>
              {/* Telefono */}
              <Grid item xs={4} sm={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>Telefono</InputLabel>
                <TextField
                  fullWidth
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>
              {/* Sr/Sra */}
              <Grid item xs={4} sm={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>Sr/Sra</InputLabel>
                <TextField
                  fullWidth
                  name="srSra"
                  value={formData.srSra}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>
              {/* Domicilio */}
              <Grid item xs={4} sm={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>Domicilio</InputLabel>
                <TextField
                  fullWidth
                  name="domicilio"
                  value={formData.domicilio}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>
              {/* Telefono tenedor */}
              <Grid item xs={4} sm={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>Telefono Tenedor Responsable</InputLabel>
                <TextField
                  fullWidth
                  name="telefono"
                  value={formData.telefono}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>
              {/* Nombre Animal */}
              <Grid item xs={4} sm={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>Nombre Animal</InputLabel>
                <TextField
                  fullWidth
                  name="animalName"
                  value={formData.animalName}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>
              {/* Raza */}
              <Grid item xs={4} sm={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>Raza</InputLabel>
                <TextField
                  fullWidth
                  name="raza"
                  value={formData.raza}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>
              {/* Sexo */}
              <Grid item xs={4} sm={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>Sexo</InputLabel>
                <TextField
                  fullWidth
                  name="sexo"
                  value={formData.sexo}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>
              {/* Edad */}
              <Grid item xs={4} sm={4}>
                <InputLabel sx={{ fontSize: '0.875rem' }}>Edad</InputLabel>
                <TextField
                  fullWidth
                  name="edad"
                  value={formData.edad}
                  onChange={handleChange}
                  required
                  sx={{ fontSize: '0.875rem' }}
                />
              </Grid>
              {/* Botón de envío */}
              <Grid item xs={10}>
                <SoftButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ marginTop: "1.5rem", borderRadius: "8px" }}
                >
                  Generar Contrato
                </SoftButton>
              </Grid>
            </Grid>
          </form>
        ) : (
          <>
            <embed ref={pdfRef} type="application/pdf" width="100%" height="1000px" />
            <Grid container spacing={2}>
              <Grid item xs={10} sm={6}>
                <SoftButton
                  onClick={handleBack}
                  variant="contained"
                  color="dark"
                  fullWidth
                  sx={{ marginTop: "1rem", borderRadius: "8px" }}
                >
                  Volver a Editar
                </SoftButton>
              </Grid>
              <Grid item xs={10} sm={6}>
                <SoftButton
                  variant="contained"
                  color="success"
                  fullWidth
                  sx={{ marginTop: "1rem", borderRadius: "8px" }}
                >
                  Guardar Contrato
                </SoftButton>
              </Grid>
            </Grid>
          </>
        )}
      </SoftBox>
    </DashboardLayout>
  );
};

export default ContractConsentimiento; 