import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Icon, Table as MuiTable, Tooltip, TableBody, TableContainer, TableRow, TableCell } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckIcon from '@mui/icons-material/Check';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useQuery, gql } from '@apollo/client';
import PdfFormulariosRC from "layouts/formDiaUno/components/PDF/PdfFormulariosRC"; 
import { API_URL } from "services/config";

function DataTableFrc({ columns, rows, user, onEdit, onDelete }) {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const userId = user?.id;

  const filteredRows = useMemo(() => {
    return rows.filter(row => row.userId === userId); 
  }, [rows, userId]);

  const renderColumns = columns.map(({ id, label, align, width }) => (
    <TableCell
      key={id}
      align={align}
      pt={1.5}
      pb={1.25}
      sx={{
        fontSize: typography.size.xxs,
        fontWeight: typography.fontWeightBold,
        color: "secondary",
        opacity: 0.7,
        borderBottom: `${borders.borderWidth[1]} solid ${colors.light.main}`,
        width: width || "auto",
      }}
    >
      <SoftTypography variant="p" fontWeight="bold" color="dark">
        {label ? label.toUpperCase() : ""}
      </SoftTypography>
    </TableCell>
  ));

  const renderRows = filteredRows.map((row) => (
    <TableRow key={uuidv4()} hover>
      {columns.map(({ id, align }) => (
        <TableCell key={id} align={align}>
          <SoftTypography variant="button" color="secondary" fontSize={typography.size.xs}>
            {row[id] !== undefined ? row[id] : ""}
          </SoftTypography>
        </TableCell>
      ))}

      {/* Columna de iconos */}
      <TableCell align="center"
      sx={{
        padding: '4px',
        margin: '0',
        width: '160px',
      }}>
        {/* Ver PDF */}
        <Tooltip title="Ver PDF" arrow>
          <Icon
            onClick={() => {
              const pdfUrl = `${API_URL}/${row?.documentos[0]?.file}`;
              console.log('PDF URL:', pdfUrl);
              if (row?.documentos?.length > 0) {
                window.open(pdfUrl, "_blank");
              }
            }}
            
            style={{ cursor: 'pointer', marginRight: '8px', color: '#800000' }}
          
          >
            <DescriptionIcon />
          </Icon>
        </Tooltip>
        {/* Planilla Visitas Recuento */}
        <Tooltip title="Planilla Visitas Recuento" arrow>
          <Icon
            onClick={() => {
              navigate(`/proyectos/PlanillaVisitasRecuento/${row.id}`);
            }}
            sx={{
              fontSize: '18px',
              color: 'green',
              marginRight: '8px',
            }}
          >
            <AddIcon />
          </Icon>
        </Tooltip>

        {/* Planilla Registro Helmintos */}
        <Tooltip title="Planilla Registro Helmintos" arrow>
          <Icon
            onClick={() => {
              navigate(`/proyectos/PlanRegistroHelmintos/${row.id}`);
            }}
            sx={{
              fontSize: '18px',
              color: 'orange',
              marginRight: '8px',
            }}
          >
            <AddIcon />
          </Icon>
        </Tooltip>

        {/* Consentimiento */}
        <Tooltip title="Consentimiento" arrow>
          <Icon
            onClick={() => { navigate(`/proyectos/consentimiento/${row.id}`); }}
            sx={{
              fontSize: '18px',
              color: 'purple',
              marginRight: '8px',
            }}
          >
            <CheckIcon />
          </Icon>
        </Tooltip>
        {/* Contrato con veterinario */}
        <Tooltip title="Contrato c/ Veterinaria" arrow>
          <Icon
            onClick={() => {
              navigate(`/proyectos/contrato/${row.id}`);
              }}
            sx={{
              fontSize: '18px',
              color: 'purple',
              marginRight: '8px',
            }}
          >
            <DescriptionIcon />
          </Icon>
        </Tooltip>

        {/* Eliminar */}
        <Tooltip title="Eliminar" arrow>
          <Icon
            onClick={() => { onDelete(row.id); }}
            sx={{
              fontSize: '18px',
              color: 'red',
            }}
          >
            <DeleteIcon />
          </Icon>
        </Tooltip>
      </TableCell>
    </TableRow>
  ));

  return useMemo(() => (
    <SoftBox>
      
      <SoftBox display="flex" justifyContent="flex-end" mb={2} mr={3} >
        <SoftButton
          onClick={() => navigate("/proyectos")}
          variant="contained"
          color="primary"
        >
          Volver a Proyectos
        </SoftButton>
      </SoftBox>

      {/* Tabla con sus columnas y filas optimizadas con useMemo */}
      <TableContainer>
        <MuiTable>
          <SoftBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </SoftBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    </SoftBox>
  ), [columns, rows, onEdit, onDelete]);
}

DataTableFrc.defaultProps = {
  columns: [],
  rows: [],
  onEdit: () => {},
  onDelete: () => {},
};

DataTableFrc.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    align: PropTypes.string,
    width: PropTypes.string,
  })),
  rows: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DataTableFrc;
