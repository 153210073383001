// Kaizen Dashboard layouts
import ErrorHandler from "components/ErrorHandler";
import SignIn from "layouts/authentication/sign-in";
import Compras from "layouts/compras";
import Proyectos from "layouts/proyectos";
import Productos from "layouts/productos";
import Soporte from "layouts/soporte";
import Sucursal from "layouts/sucursal";
import Usuarios from "layouts/usuarios";
import Clientes from "layouts/clientes";
import FormD1 from "layouts/formDiaUno";
import Proveedores from "layouts/proveedor";
import FormulariosFRC from "layouts/formDiaUno";
import RemitoSedePDF from "layouts/PDF/remitoSede";
import FormFrc from "layouts/formDiaUno/components/Frc";
import ContractPDF from "layouts/PDF/contrato"; //prueba pdf contrato
import ContractPDFLab from "layouts/PDF/contratoLaboratorio"; //prueba pdf contrato
import ContractConsentimiento from "layouts/PDF/consentimiento";
import RegistroVisitasForm from "layouts/formDiaUno/components/PlanillaVisitasRecuento";
import RegistroHelmintos from "layouts/formDiaUno/components/PlanRegistroHelmintos";



// Kaizen Dashboard icons  
import {
  Inventory,
  ShoppingCart,
  Task,
  Person,
  FormatListBulleted,
  Summarize,
  AccountTree,
  AddLocationAlt,
  Groups,
  QueryStats,
  SupervisorAccount,
  Dashboard,
  Group,
  MapOutlined,
  SupportAgent,
} from "@mui/icons-material";
import PerfilProveedor from "layouts/perfilProveedor";
import Recepcion from "layouts/recepcion";
import Progreso from "layouts/progreso";

const routes = [
  {
    type: "collapse",
    name: "Proyectos",
    key: "proyectos",
    route: "/proyectos",
    icon: <AccountTree />,
    component: <Proyectos />,
    noCollapse: true,
    permiso: "Proyectos",
  },
  {
    name: "FRC",
    key: "form-frc",
    route: "/proyectos/form-frc/:id",
    icon: <Summarize />,
    component: <FormFrc />,
    noCollapse: true,
    permiso: "Proyectos"
  },
  {
    name: "Lista Formularios",
    key: "formularioAnimalD1",
    route: "/proyectos/Formulario_Registro_Clinico/:proyectoId",
    icon: <FormatListBulleted />,
    component: <FormulariosFRC />,
    noCollapse: true,
    permiso: "Proyectos",
    
  },  
  {
    
    name: "EFI003",
    key: "efi003",
    route: "/proyectos/PlanillaVisitasRecuento/:id",
    icon: <Groups />,
    component: <RegistroVisitasForm />,
    noCollapse: true,
    permiso: "Clientes",
  },
  {
    
    name: "Helmintos",
    key: "helmintos",
    route: "/proyectos/PlanRegistroHelmintos/:id",
    icon: <Groups />,
    component: <RegistroHelmintos />,
    noCollapse: true,
    permiso: "Clientes",
  },{
    name: "pdf",
    key: "pdf",
    route: "/proyectos/contrato/:id",
    icon: <SupportAgent />,
    component: <ContractPDF />,
    noCollapse: true,
    permiso: "Proyectos",
  },
  {
    type: "collapse",
    name: "Productos",
    key: "productos",
    route: "/productos",
    icon: <Inventory />,
    component: <Productos />,
    noCollapse: true,
    permiso: "Productos",
  },
  {
    route: "/productos/pdf/:id",
    component: <RemitoSedePDF />,
  },
  {
    route: "/recepcion/:id",
    component: <Recepcion />,
  },
  {
    type: "collapse",
    name: "Clientes",
    key: "clientes",
    route: "/clientes",
    icon: <Groups />,
    component: <Clientes />,
    noCollapse: true,
    permiso: "Clientes",
  },
  {
    type: "collapse",
    name: "Proveedores",
    key: "proveedores",
    route: "/proveedores",
    icon: <SupervisorAccount />,
    component: <Proveedores />,
    noCollapse: true,
    permiso: "Proveedores",
  },
  {
    type: "collapse",
    name: "Usuarios",
    key: "usuarios",
    route: "/usuarios",
    icon: <Group />,
    component: <Usuarios />,
    noCollapse: true,
    permiso: "Usuarios",
  },
  
  {
    type: "collapse",
    name: "Progreso",
    key: "progreso",
    route: "/progreso",
    icon: <QueryStats />,
    component: <Progreso />,
    noCollapse: true,
    permiso: "Progreso",
  },
  {
    type: "collapse",
    name: "Soporte",
    key: "soporte",
    route: "/soporte",
    icon: <SupportAgent />,
    component: <Soporte />,
    noCollapse: true,
  },

  { type: "collapse",
    name: "pdflab",
    key: "pdflab",
    route: "/proyectos/contratoLaboratorio/:id",
    icon: <SupportAgent />,
    component: <ContractPDFLab />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Consentimiento", 
    key: "consentimiento",
    route: "/proyectos/consentimiento/:id",
    icon: <SupportAgent />,
    component: <ContractConsentimiento />,    
    noCollapse: true,
  },
  {
    route: "/error",
    component: <ErrorHandler />,
  },
  {
    route: "/login",
    component: <SignIn />,
  },
];

export default routes;
